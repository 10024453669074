import React from 'react';
import Layout from '../components/layout';
import mail from '../images/mail.png';
import phone from '../images/phone.png';
import bfLogo from '../images/blue-forest-logo.png';
import '../styles/main.scss';
class Contact extends React.Component {
  render() {
    return (
      <Layout noBottomLogo>
        <div className="contact mb-5">
          <h2 className="head-underlined mb-5 mt-5">Skontaktuj się z nami</h2>
          <div className="mb-5">
            <p>
              <b>Adres Inwestycji Zatoka Pucka:</b>
            </p>
            <p>54-312 Wrocław, ulica Pucka, Kuźniki</p>
            <p>
              <img
                src={mail}
                className="mr-3"
                style={{ width: '30px' }}
                alt="email"
              />
              <a href="mailto:sprzedaz@bfd.com.pl">
                Email: sprzedaz@bfd.com.pl
              </a>
            </p>
          </div>
          <div className="mb-5">
            <p>
              <b>Dział Sprzedaży: Marcin Buczak</b>
            </p>
            <a href="tel:609397303">
              <img
                src={phone}
                className="mr-3"
                style={{ width: '30px' }}
                alt="phone"
              />
              Telefon: +48 609 397 303
            </a>
            <p className="mt-2">
              <img
                src={mail}
                className="mr-3"
                style={{ width: '30px' }}
                alt="email"
              />
              <a href="mailto:marcin.buczak@bfd.com.pl">
                Email: marcin.buczak@bfd.com.pl
              </a>
            </p>
          </div>
          <div className="mb-5">
            <p>
              <b>Biuro Dewelopera: Magdalena Frątczak</b>
            </p>
            <a href="tel:788738656" className="mt-4">
              <img
                src={phone}
                className="mr-3"
                style={{ width: '30px' }}
                alt="phone"
              />
              Telefon: +48 788 738 656
            </a>
            <p className="mt-2">
              <img
                src={mail}
                className="mr-3"
                style={{ width: '30px' }}
                alt="email"
              />
              <a href="mailto:magdalena.fratczak@bfd.com.pl">
                Email: magdalena.fratczak@bfd.com.pl
              </a>
            </p>
          </div>
          <div>
            <p>
              <b>Dane rejestrowe:</b>
            </p>
            <p>
              <b>Blue Forest Development sp. z o.o.</b>
              <br /> 53-527 Wrocław, ulica Wincentego Stysia nr 61/7
              <br /> NIP: 8992840396
              <br /> Regon: 369365721 <br />
              KRS: 0000716116
            </p>
          </div>
          <img
            className="img-fluid bf-logo"
            src={bfLogo}
            alt="blue forest development"
          />
        </div>
      </Layout>
    );
  }
}

export default Contact;
